(function (wpm, $, undefined) {

	wpm.sendEventPayloadToServer = async payload => {

		const isServerToServerEnabled = () => {

			if (wpmDataLayer?.pixels?.facebook?.capi) return true

			return false
		}

		const isServerToServerDisabled = () => {
			return !isServerToServerEnabled()
		}

		const sendPayloadUsingFetch = payload => {

			// Send data to pmw public API with fetch API
			fetch(wpm.root + "pmw/v1/sse/", {
				method     : "POST",
				credentials: "same-origin",
				headers    : {
					"Content-Type": "application/json",
				},
				body       : JSON.stringify(payload),
				keepalive  : true,	// keep connection alive until request is finished
			})
				.then(response => response.json())
				.then(message => {
					if (!message.success) {
						console.error(message)
					}
				})
				.catch(error => {
					console.error(error)
				})

		}

		const sendPayloadUsingNavigatorSendBeacon = data => {
			// https://css-tricks.com/send-an-http-request-on-page-exit/
			navigator.sendBeacon(wpm.root + "pmw/v1/sse/", data)
		}

		try {
			if (isServerToServerDisabled()) return

			if (await wpm.isRestEndpointAvailable()) { // Send data using REST API

				const blob = new Blob([JSON.stringify(payload)], {type: "application/json; charset=UTF-8"})

				// navigator.sendBeacon has a limit of 64kb. Fall back to fetch if the blob is too large.
				if (blob.size < 60000) {
					sendPayloadUsingNavigatorSendBeacon(blob)
				} else {
					sendPayloadUsingFetch(payload)
				}
			} else { // Send data using wp-ajax

				jQuery.ajax(
					{
						type    : "post",
						dataType: "json",
						url     : wpm.ajax_url,
						data    : {
							action: "pmw_server_to_server_event",
							data  : payload,
						},
						success : message => {
							// console.log(message)
						},
						error   : message => {
							console.error(message)
						},
					})
			}

		} catch (e) {
			console.error(e)
		}
	}

}(window.wpm = window.wpm || {}, jQuery))
